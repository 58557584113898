import { ERC20_ABI, toLowerUnit } from "@react-dapp/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers, utils } from "ethers";
import { accountApiCall, apiCall, metaDataApiCall } from "src/config/apiConfig";
import { ERC1155_ADDRESS, TOKEN_ADDRESS } from "src/config/config";
import { erc1155_abi } from "src/contracts/abi/ERC1155";

const initialState: StateInterface = {
  loading: false,
  user: {
    metadatas: [],
    balance: "0",
    nftBalance: 0,
    stakeTokenId: undefined,
  },
};

interface StateInterface {
  loading: boolean;
  user: {
    metadatas: IMetadata[];
    balance: string;
    nftBalance: number;
    stakeTokenId?: string;
  };
}

interface IMetadata {
  name: string;
  tokenId: string | number;
  image: string;
  attributes: {
    trait_type: string;
    value: string;
    display_type?: string;
  }[];
}

export const fetchBalance = createAsyncThunk("user/getTokenIds", async (thunkAPI) => {
  try {
    const res = await accountApiCall.get("/minted");

    let balancesArr: string[] = [];

    const { ethereum } = window;

    if (!ethereum) {
      console.log("Ethereum instance not found");
      return;
    }

    const accounts = await ethereum.request({ method: "eth_accounts" });
    const account = accounts[0];

    const provider = new ethers.providers.Web3Provider(ethereum);
    const ERC1155Contract = new ethers.Contract(ERC1155_ADDRESS, erc1155_abi, provider);
    const ERC20Contract = new ethers.Contract(TOKEN_ADDRESS, ERC20_ABI, provider);

    let tokenBalance = await ERC20Contract.balanceOf(account);

    tokenBalance = toLowerUnit(tokenBalance.toString()).toFormat(0);

    for (let i = 0; i < res.data.length; i++) {
      balancesArr.push(account);
    }
    const batchBalance = await ERC1155Contract.balanceOfBatch(balancesArr, res.data);
    let totalNftBalance: number = 0;
    let metadataArr: any[] = [];

    for (let i = 0; i < batchBalance.length; i++) {
      const balance = Number(batchBalance[i]);
      if (balance === 0) {
        continue;
      }
      totalNftBalance += balance;

      const fetchMetadata = async (tokenId: number) => {
        const metadata = await metaDataApiCall.get(`gameantz/${tokenId}`);
        return metadata.data;
      };

      const metadata = fetchMetadata(res.data[i]);

      metadataArr = [...metadataArr, metadata];
    }

    const metadatas: IMetadata[] = await Promise.all(metadataArr);
    const stakingIds = metadatas.filter((e) => e.attributes.find((a) => a.trait_type === "STAKING MULTIPLIER"));

    let tokenId = undefined;
    let maxMultiplier = 0;
    for (let i = 0; i < stakingIds.length; i++) {
      const multiplier = Number(stakingIds[i].attributes.find((a) => a.trait_type === "STAKING MULTIPLIER")?.value);
      if (multiplier > maxMultiplier) {
        maxMultiplier = multiplier;
        tokenId = stakingIds[i].tokenId.toString();
      }
    }

    const stakeTokenId = tokenId;
    return { totalNftBalance, metadatas, tokenBalance, stakeTokenId };
  } catch (error) {
    console.log("Error", error);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state: StateInterface, action: any) => {
      state.user = action.payload;
    },
    setUserLoading: (state: StateInterface, action: any) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.user.nftBalance = action.payload?.totalNftBalance as number;
      state.user.balance = action.payload?.tokenBalance;
      state.user.metadatas = action.payload?.metadatas as any[];
      state.user.stakeTokenId = action.payload?.stakeTokenId;
    });
  },
});

export const { setUser, setUserLoading } = userSlice.actions;

export default userSlice.reducer;
