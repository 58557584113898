import React from "react";
import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import coins from "src/assets/icons/coins.png";
import nftcount from "src/assets/icons/nftcount.png";
import nft1 from "src/assets/images/nft1.png";
import nft2 from "src/assets/images/nft2.png";
import nft3 from "src/assets/images/nft3.png";
import nft4 from "src/assets/images/nft4.png";
import NFTCard from "src/components/NFTCard/NFTCard";
import { fetchBalance } from "src/state/user/userReducer";
import { RootState } from "src/state";
import { useEthers } from "@react-dapp/utils";
import { ethers } from "ethers";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "70vw",
    position: "relative",
    right: "-6%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    margin: "50px auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      right: 0,
    },
  },
  stats: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 40,
    gap: 100,
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      gap: 30,
      justifyContent: "center",
    },
  },
  textContainer: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    "& > img": {
      width: 70,
    },
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 50,
    minHeight: "250px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

const Account = () => {
  const classes = useStyles();
  const { balance, nftBalance, metadatas } = useSelector((state: RootState) => state.user.user);

  return (
    <div className={classes.root}>
      <div className={classes.stats}>
        <div className={classes.textContainer}>
          <img src={coins} />
          <div>
            <Typography>Balance</Typography>
            <Typography variant="h4" fontWeight={600}>
              {balance}
            </Typography>
          </div>
        </div>
        <div className={classes.textContainer}>
          <img src={nftcount} />
          <div>
            <Typography>NFT Balance</Typography>
            <Typography variant="h4" fontWeight={600}>
              {nftBalance}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.cardsContainer}>
        {metadatas.map((nft, i) => (
          <NFTCard key={i} name={nft.name} image={nft.image} tokenId={nft.tokenId} />
        ))}
      </div>
    </div>
  );
};

export default Account;
