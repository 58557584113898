import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: "2px solid #fff",
    boxSizing: "content-box",
    width: 210,
    minHeight: "120px",
    // height: 360,
    [theme.breakpoints.down("sm")]: {
      //   width: "90%",
      //   width: 300,
      //   height: 470,
      //   height: "100%",
    },
  },
  imgContainer: {
    width: "inherit",
    "& > img": {
      minHeight: "120px",
      width: "inherit",
    },
  },
  textContainer: {
    padding: "20px",
  },
  name: {
    color: theme.palette.text.primary,
  },
  price: {},
}));

interface INFTCard {
  image: string;
  name: string;
  tokenId: number | string;
}

const NFTCard = ({ image, name, tokenId }: INFTCard) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer}>
        <img src={image} alt={name} />
      </div>
      <div className={classes.textContainer}>
        <Typography variant="h5" className={classes.name}>
          {name}
        </Typography>
        <Typography className={classes.price}>ID # {tokenId}</Typography>
      </div>
    </div>
  );
};

export default NFTCard;
