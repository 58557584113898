import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// declare module "@mui/material/Button" {
//   interface ButtonPropsVariantOverrides {
//     containedLeft: true;
//     containedRight: true;
//   }
// }

// declare module "@mui/material/styles" {
//   interface Theme {
//     textShadows: {
//       primary: string;
//       secondary: string;
//       white: string;
//     };

//     fonts: string[];
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     textShadows?: {
//       primary?: string;
//       secondary?: string;
//       white?: string;
//     };
//     fonts?: string[];
//   }
// }

const primary = "#7C69E3";

let theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    text: {
      // #FCC326
      primary: "#7C69E3",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            // border: "1px solid white",
            color: "white",
            // "& .MuiOutlinedInput-root.Mui-focused": {
            //   border: "1px solid white",
            // // },
            "& .MuiOutlinedInput-input": {
              borderRadius: 0,

              color: "white",
            },
          },
        },
      ],
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
