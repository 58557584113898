import { Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logoLetter from "src/assets/logos/logo-letter.png";
import viper from "src/assets/images/viper.png";
import { usePoolNFT } from "@nftvillage/farms-sdk";
import { useTimer } from "src/hooks/useTimer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    width: "250px",
    padding: "20px",
    border: "2px dotted white",
    position: "relative",
    top: -30,
    background: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      top: 0,
      width: "250px",
    },
  },
  logo: {
    width: 80,
    position: "absolute",
    transform: " matrix(-0.91, -0.41, -0.41, 0.91, 0, 0)",
    top: "80%",
    left: "-10%",
    [theme.breakpoints.down("md")]: {
      width: 100,
      top: "70%",
    },
  },
  viper: {
    width: 250,
    position: "absolute",
    top: "0%",
    left: "70%",
    [theme.breakpoints.down("lg")]: {
      zIndex: -1,
      top: "-40%",
      left: "-100%",
      opacity: 0.3,
      mixBlendMode: "luminosity",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Reward = () => {
  const classes = useStyles();
  const pool = usePoolNFT(1);
  const { timeLeft, timeFinished } = useTimer(pool?.details?.userInfo?.canHarvestAt!);

  return (
    <div className={classes.root}>
      <Typography variant="h5" fontFamily={"Oswald"} align="center" component={"div"} sx={{ color: "white" }}>
        {/* {pool?.rewards[0].apy ?? "177.51"}% APY */}
        <Typography fontFamily={"Oswald"} align="center" variant="h4" sx={{ color: "white" }}>
          {pool?.rewards[0].rewards ?? "0.00"}
        </Typography>
        <Typography fontFamily={"Oswald"} align="center" sx={{ color: "white" }}>
          ANTZ
        </Typography>
      </Typography>
      <Button
        onClick={() => pool?.harvestInfo.harvest()}
        variant="contained"
        disableElevation
        sx={{ background: "black", width: "100%", mt: 2 }}
      >
        {!timeFinished
          ? `${timeLeft.days}D : ${timeLeft.hours}H : ${timeLeft.minutes}M : ${timeLeft.seconds}S`
          : pool?.harvestInfo.pending
          ? "Pending..."
          : "Harvest"}
      </Button>
      <img src={viper} className={classes.viper} />
      <img src={logoLetter} className={classes.logo} />
    </div>
  );
};

export default Reward;
