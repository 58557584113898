import {
  toBigNumber,
  toLowerUnit,
  useLp,
  ERC20_ABI,
  useEthers,
  DEAD_ADDRESS,
  ZERO_ADDRESS,
  useUSDLp,
} from "@react-dapp/utils";
import { PAIR_ADDRESS, TOKEN_ADDRESS } from "src/config/config";
import { useEffect, useState } from "react";
import { Multicall, ContractCallResults, ContractCallContext } from "ethereum-multicall";

const reserve = "0xC11e5d77fC3f79A83aA304C0084C2a7266D00cC9";

export const useTokenStats = () => {
  const lp = useUSDLp(PAIR_ADDRESS);
  const { ethers, account } = useEthers();

  const [circulatingSupply, setCirculatingSupply] = useState("0");
  const [burnBalance, setBurnBalance] = useState("0");
  const [displayBalance, setDisplayBalance] = useState("0");
  const [totalSupply, setTotalSupply] = useState("0");
  const [price, setPrice] = useState("");
  const [balanceUsd, setBalanceUsd] = useState("");
  const [marketCapUsd, setMarketCapUsd] = useState("");

  useEffect(() => {
    const fetch = async () => {
      if (lp?.baseTokenUsdPrice && ethers && account) {
        setPrice(lp.baseTokenUsdPrice.toFixed(11));

        const multicall = new Multicall({ ethersProvider: ethers, tryAggregate: false });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: "testContract",
            contractAddress: TOKEN_ADDRESS,
            abi: ERC20_ABI,
            calls: [
              { reference: "_totalSupply", methodName: "totalSupply", methodParameters: [] },
              { reference: "pairBalance", methodName: "balanceOf", methodParameters: [PAIR_ADDRESS] },
              { reference: "zeroBalance", methodName: "balanceOf", methodParameters: [ZERO_ADDRESS] },
              { reference: "deadBalance", methodName: "balanceOf", methodParameters: [DEAD_ADDRESS] },
              { reference: "accountBalance", methodName: "balanceOf", methodParameters: [account] },
              { reference: "reserveBalance", methodName: "balanceOf", methodParameters: [reserve] },
            ],
          },
        ];

        const { results }: ContractCallResults = await multicall.call(contractCallContext);

        const _totalSupply = toLowerUnit(
          toBigNumber(results.testContract.callsReturnContext[0].returnValues[0]).toFixed()
        );
        const _pairBalance = toLowerUnit(
          toBigNumber(results.testContract.callsReturnContext[1].returnValues[0]).toFixed()
        );
        const _zeroBalance = toLowerUnit(
          toBigNumber(results.testContract.callsReturnContext[2].returnValues[0]).toFixed()
        );
        const _deadBalance = toLowerUnit(
          toBigNumber(results.testContract.callsReturnContext[3].returnValues[0]).toFixed()
        );
        const _accountBalance = toLowerUnit(
          toBigNumber(results.testContract.callsReturnContext[4].returnValues[0]).toFixed()
        );

        const _reserveBalance = toLowerUnit(
          toBigNumber(results.testContract.callsReturnContext[5].returnValues[0]).toFixed()
        );

        const _price = lp.baseTokenUsdPrice;
        const circulatingSupply = _totalSupply.minus(_deadBalance).minus(_zeroBalance).minus(_reserveBalance);
        const _burnBalance = _zeroBalance.plus(_deadBalance).toFormat(0);
        const _balanceUsd = _accountBalance.times(_price).toFormat(5);
        const _marketCapUsd = circulatingSupply.times(_price).toFormat(0);

        setBalanceUsd(_balanceUsd.toString());
        setTotalSupply(_totalSupply.toFormat(0));
        setCirculatingSupply(circulatingSupply.toFormat(0));
        setBurnBalance(_burnBalance);
        setDisplayBalance(_accountBalance.toFormat(0));
        setMarketCapUsd(_marketCapUsd);
      }
    };

    fetch();
  }, [ethers, lp.lp?.price.baseTokenPrice, account]);

  return {
    price: price,
    circulatingSupply,
    displayBalance,
    balanceUsd,
    marketCapUsd,
    totalSupply,
    burnBalance,
  };
};
