import { Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Theme } from "reapop";

interface UserNFTDetailProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "90px",
    marginLeft: "260px",
  },
  con: {
    background: "#181728",
    border: "2px solid #7C69E3",
    height: "681px",
    width: "90%",
  },
  crossCon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    width: "37px !important",
    height: "37px",
    background: "#E6E6E6",
    display: "flex",
    padding: "0px",
    borderRadius: "0px",
    alignItems: "center",
  },
}));

export const UserNFTDetail: React.FC<UserNFTDetailProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.con}>
        <div className={classes.crossCon}>
          <Button className={classes.btn}>X</Button>
        </div>
      </div>
    </div>
  );
};
